<template>
  <v-container fluid>
    <v-toolbar flat>
      <v-toolbar-title>Konfigurationen bearbeiten</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="configs.length > 1" outlined @click="updateAllConfigs">Alle Configs speichern</v-btn>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="configs"
      :loading="isLoading"
      :footer-props="{
        'items-per-page-all-text': 'Alle',
        'items-per-page-text': 'Zeilen pro Seite',
      }"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <td>{{ item.module }}</td>
            <td>{{ item.config }}</td>
            <td>
              <v-text-field
                style="padding: 16px 0"
                dense
                single-line
                filled
                hide-details
                v-model="item.value"
              ></v-text-field>
            </td>
            <td>{{ item.type }}</td>
            <td>
              <v-btn icon small @click="updateConfig(item)">
                <v-icon>save</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import HTTP from "@/common/HTTP";

export default {
  data() {
    return {
      configs: [],
      isLoading: false,
      headers: [
        { text: "Modul", align: "left", value: "module", width: "15%" },
        { text: "Konfiguration", align: "left", value: "config", width: "30%" },
        { text: "Wert", align: "left", value: "value", width: "45%", sortable: false },
        { text: "Typ", align: "left", value: "type", width: "8%", sortable: false },
        { text: "Actions", width: "5%", sortable: false },
      ],
    };
  },

  created() {
    this.fetchConfigs();
  },

  methods: {
    fetchConfigs() {
      this.isLoading = true;
      HTTP.get("/conf")
        .then(({ data }) => {
          this.configs = data;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    updateConfig(config) {
      this.requestFailed = false;
      HTTP.post(`/conf/${config.module}/${config.config}`, config)
        .then(() => this.$notifier.info("Config wurden erfolgreich gespeichert"))
        .catch(error => this.$notifier.error("Config speichern fehlgeschlagen", error));
    },

    updateAllConfigs() {
      this.requestFailed = false;
      HTTP.post("/conf", this.configs)
        .then(() => this.$notifier.info("Configs wurden erfolgreich gespeichert"))
        .catch(error => this.$notifier.error("Config speichern fehlgeschlagen", error));
    },
  },

  name: "Configuration",
};
</script>
