<template>
  <v-snackbar
    v-model="isSnackbarVisible"
    :multi-line="snackbarSettings.multiLine"
    :right="snackbarSettings.x === 'right'"
    :timeout="snackbarSettings.timeout"
    :top="snackbarSettings.y === 'top'"
    :color="snackbarSettings.color"
  >
    <div class="c-flex row align-center justify-between">
      {{ text }}

      <v-btn dark icon @click="isSnackbarVisible = false"><v-icon>cancel</v-icon></v-btn>
    </div>
  </v-snackbar>
</template>

<script>
import bus from "../Bus";

export default {
  data: () => ({
    isSnackbarVisible: false,
    text: "",
    snackbarSettingsDefault: {
      multiLine: true,
      timeout: 6000,
      x: "right",
      y: "top",
      color: null,
    },
    snackbarSettings: {},
  }),

  created() {
    this.resetSnackbarSettings();
    bus.$on("log", this.log);
  },

  methods: {
    log(type, text, optionals) {
      this.resetSnackbarSettings();

      this.text = text;

      switch (type) {
        case "error":
          this.snackbarSettings.color = "red";
          // eslint-disable-next-line
          console.error(text, optionals.errors);
          break;
        case "warn":
          this.snackbarSettings.color = "orange";
          break;
        default:
          break;
      }

      this.isSnackbarVisible = true;
    },

    resetSnackbarSettings() {
      this.snackbarSettings = { ...this.snackbarSettingsDefault };
    },
  },
};
</script>
