<style scoped lang="scss">
div.confirm-container {
  display: inline;
}
</style>

<template>
  <div class="confirm-container">
    <v-dialog v-model="dialog" persistent max-width="400">
      <template v-slot:activator="{ on }">
        <div v-on="on" class="darkmode-disabled"><slot></slot></div>
      </template>

      <v-card>
        <v-card-title class="headline">
          <slot name="title"> Do you really want to delete this? </slot>
        </v-card-title>
        <v-card-text>
          <slot name="message"> This can not be undone. </slot>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">Cancel</v-btn>
          <v-btn color="red" depressed @click="confirmedDelete" dark>Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },

  props: {
    action: Function,
    item: [Object, Array, String, Number],
  },

  methods: {
    confirmedDelete() {
      this.dialog = false;
      this.action(this.item);
    },
  },

  name: "DeleteConfirm",
};
</script>
