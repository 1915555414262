export default {
  beforeDestroy() {
    window.removeEventListener("resize", this.updateIsMobile, { passive: true });
  },

  mounted() {
    this.updateIsMobile();
    window.addEventListener("resize", this.updateIsMobile, { passive: true });
  },

  data() {
    return {
      isMobile: false,
      breakpoint: 600,
    };
  },

  computed: {
    isMobileByBreakpoint() {
      return breakpoint => {
        this.breakpoint = breakpoint;
        this.updateIsMobile();
        return this.isMobile;
      };
    },
  },

  methods: {
    updateIsMobile() {
      this.isMobile = window.innerWidth < (this.mobileBreakpoint || this.breakpoint);
    },
  },
};
