<template>
  <div class="pdf c-flex column" v-if="src" @keyup="optionallyExitFullScreen">
    <div style="position: absolute; z-index: 50; top: 8px">
      <v-menu open-on-hover :close-on-content-click="false" top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab color="white" depressed v-bind="attrs" v-on="on">
            <v-icon>menu_open</v-icon>
          </v-btn>
        </template>

        <v-list class="pa-2" style="width: 400px">
          <div class="c-flex column justify-center pa-1">
            <span class="pb-1 pl-1">Filename</span>
            <Header :no-top-pad="true" class="pb-8">
              <v-text-field
                filled
                dense
                hide-details
                :value="filename"
                @change="$emit('filenameChanged', $event)"
                @keyup.native.enter="$emit('filenameChanged', $event.target.value)"
              >
              </v-text-field>
            </Header>

            <div class="c-flex column pdf-settings">
              <span class="pb-1 pl-1">Search</span>
              <div class="c-flex row align-center mb-8">
                <v-text-field
                  class="c-flex grow"
                  filled
                  dense
                  hide-details
                  v-model="keyword"
                  @keyup.native.enter="handleMatchChange(false, canFindNext)"
                >
                </v-text-field>

                <v-btn
                  small
                  depressed
                  class="mr-1 ml-1"
                  :disabled="!canFindPre"
                  @click="handleMatchChange(true, canFindPre)"
                >
                  <v-icon>skip_previous</v-icon>
                </v-btn>

                <v-btn small depressed :disabled="!canFindNext" @click="handleMatchChange(false, canFindNext)">
                  <v-icon>skip_next</v-icon>
                </v-btn>
              </div>

              <span class="pb-1 pl-1">Page</span>
              <div class="c-flex row align-center mb-8">
                <v-btn small depressed class="mr-2" :disabled="!canPageDown" @click="handlePageChange(-1, canPageDown)">
                  <v-icon>arrow_upward</v-icon>
                </v-btn>

                <v-btn small depressed class="mr-2" :disabled="!canPageUp" @click="handlePageChange(1, canPageUp)">
                  <v-icon>arrow_downward</v-icon>
                </v-btn>

                <v-text-field
                  class="c-flex grow"
                  filled
                  dense
                  hide-details
                  v-model="currentPage"
                  @keyup.native.enter="handlePageChangeByInput(currentPage)"
                  :suffix="`of ${pages}`"
                >
                </v-text-field>
                <v-btn small depressed class="ml-2" @click="handlePageChangeByInput(currentPage)">
                  <v-icon>arrow_forward</v-icon>
                </v-btn>
              </div>

              <span class="pb-1 pl-1">View</span>
              <div class="c-flex row align-center justify-between">
                <div class="c-flex row">
                  <v-btn small depressed :disabled="!canNarrow" @click="handleScaleChange(-10, canNarrow)">
                    <v-icon>zoom_out</v-icon>
                  </v-btn>
                  <span class="mr-2 ml-2">{{ currentScale }}%</span>
                  <v-btn
                    class="mr-8"
                    small
                    depressed
                    :disabled="!canEnlarge"
                    @click="handleScaleChange(10, canEnlarge)"
                  >
                    <v-icon>zoom_in</v-icon>
                  </v-btn>
                  <v-btn class="mr-8" small depressed @click="resetScale">
                    <v-icon>restart_alt</v-icon>
                  </v-btn>
                </div>

                <v-btn small depressed @click="toggleMenu()">
                  <v-icon v-if="!GlobalMenuService.isMenuOpen">close</v-icon>
                  <v-icon v-if="GlobalMenuService.isMenuOpen">fullscreen</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </v-list>
      </v-menu>
    </div>

    <div class="c-flex grow" style="position: relative">
      <pdf
        ref="pdfViewer"
        :src="src"
        @on-loaded="pdfLoaded"
        @on-search="handleSearchResult"
        @on-page-change="handlePageChangeFromPDFViewer($event, true)"
        :initial-page="userDefinedPage"
        :initial-scale="userDefinedScale / 100"
      ></pdf>
    </div>
  </div>
</template>

<script>
import pdf from "./PDFRenderer.vue";
import Header from "../util/Header.vue";
import GlobalMenuService from "../../common/GlobalMenu.service";

export default {
  components: {
    pdf,
    Header,
  },

  props: {
    src: String,
    filename: String,
    userDefinedScale: {
      type: Number,
      default: 1,
    },
    userDefinedPage: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      minScale: 50,
      maxScale: 300,
      currentScale: this.userDefinedScale,
      pages: 0,
      currentPage: this.userDefinedPage,
      keyword: "",
      currentMatch: 0,
      totalMatch: 0,
      GlobalMenuService,
    };
  },

  mounted() {
    this.addEventListeners();
  },

  beforeDestroy() {
    this.removeEventListeners();
  },

  computed: {
    canEnlarge() {
      return this.currentScale < this.maxScale;
    },
    canNarrow() {
      return this.currentScale > this.minScale;
    },
    canPageUp() {
      return this.currentPage < this.pages;
    },
    canPageDown() {
      return this.currentPage > 1;
    },
    canFindPre() {
      return this.currentMatch > 1;
    },
    canFindNext() {
      return this.currentMatch < this.totalMatch;
    },
  },

  watch: {
    keyword(newVal) {
      this.$refs.pdfViewer.cancelSearch();
      if (newVal) this.$refs.pdfViewer.search(newVal);
    },
  },

  methods: {
    pdfLoaded(pages) {
      this.pages = pages;
    },

    handleScaleChange(value, changeable) {
      if (changeable) {
        this.currentScale += value;

        this.$refs.pdfViewer.zoom(this.currentScale / 100);

        this.$emit("userScaleChange", this.currentScale / 100);
      }
    },

    resetScale() {
      this.currentScale = 100;

      this.$refs.pdfViewer.zoom(this.currentScale / 100);
      this.$emit("userScaleChange", this.currentScale / 100);
    },

    handlePageChange(value, changeable) {
      if (changeable) this.currentPage += value;
      this.$refs.pdfViewer.jumpToPage(this.currentPage);

      this.$emit("userPageChange", this.currentPage);
    },

    handlePageChangeFromPDFViewer(page) {
      this.currentPage = page;
      this.$emit("userPageChange", page);
    },

    handlePageChangeByInput(page) {
      this.currentPage = Number(page);
      this.$refs.pdfViewer.jumpToPage(this.currentPage);
    },

    handleMatchChange(prev, changeable) {
      if (changeable) {
        this.$refs.pdfViewer.searchAgain(prev);
        this.currentMatch += prev ? -1 : 1;
      }
    },

    handleSearchResult({ current, total }) {
      this.currentMatch = current;
      this.totalMatch = total;
    },

    addEventListeners() {
      window.addEventListener("resize", this.optionallyExitFullScreen, false);
      document.addEventListener("keyup", this.optionallyEnterFullscreen);
    },

    removeEventListeners() {
      window.removeEventListener("resize", this.optionallyExitFullScreen, false);
      document.removeEventListener("keyup", this.optionallyEnterFullscreen);
    },

    toggleMenu() {
      this.removeEventListeners();

      GlobalMenuService.toggleMenu();

      setTimeout(() => this.addEventListeners(), 300);
    },

    optionallyExitFullScreen(event) {
      const isFullscreen = window.innerHeight === window.screen.height;

      if (!isFullscreen) {
        GlobalMenuService.openMenu();
      }
    },

    optionallyEnterFullscreen(event) {
      if (event.key === "F11") {
        GlobalMenuService.closeMenu();
      }
    },
  },
};
</script>
