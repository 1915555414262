import HTTP from "../../common/HTTP";

export default {
  fetchPDF(id) {
    return Promise.all([HTTP.get(`/files/${id}/info`), HTTP.get(`/files/${id}`, { responseType: "arraybuffer" })]).then(
      ([fileInfo, pdfFile]) => {
        return { fileInfo, pdfFile };
      },
    );
  },
};
