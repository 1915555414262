<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on }">
      <v-btn depressed v-on="on" color="primary" class="mb-2">
        <v-icon left>add</v-icon>
        Invite a new User
      </v-btn>
    </template>

    <v-card>
      <v-form @submit.prevent="save(user)">
        <v-toolbar flat>
          <v-btn icon @click.native="close">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn type="submit" text>Save</v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field label="Firstname" v-model="user.firstName" required filled></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field label="Lastname" v-model="user.lastName" required filled></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field label="E-Mail" v-model="user.email" required filled></v-text-field>
              </v-flex>
              <v-flex xs12>
                <select-roles v-model="user.roles"></select-roles>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import HTTP from "../../common/HTTP";
import SelectRoles from "../util/SelectRoles.vue";

export default {
  components: { SelectRoles },

  data() {
    return {
      dialog: false,
      user: null,
      prototype: {
        firstName: "",
        lastName: "",
        email: "",
        roles: [],
      },
    };
  },

  watch: {
    userToEdit(user) {
      if (user) {
        this.user = { ...user };
        this.dialog = true;
      }
    },
  },

  model: {
    prop: "user",
    event: "input",
  },

  props: {
    userToEdit: Object,
  },

  created() {
    this.user = { ...this.prototype };
  },

  methods: {
    save(user) {
      if (this.isNew) {
        HTTP.post("/users", user)
          .then(this.close())
          .then(() => this.$notifier.info("The user was invited."))
          .catch(errors => {
            const text =
              errors.response.data.status === 409
                ? `The user with the email ${user.email} already exists.`
                : "The user could not be invited.";
            this.$notifier.error(text, errors);
          });
      } else {
        HTTP.patch(`/users/${user.id}`, user)
          .then(this.close())
          .then(() => this.$notifier.info("The user was updated."))
          .catch(() => this.$notifier.error("The user could not be updated."));
      }
    },

    close() {
      this.dialog = false;
      this.user = { ...this.prototype };
      this.$emit("done");
    },
  },

  computed: {
    isNew() {
      return this.user.id === undefined;
    },
    formTitle() {
      return this.isNew ? "Invite Users" : "Edit Users";
    },
  },
  name: "EditUserDialog",
};
</script>
