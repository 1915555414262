<style lang="scss" scoped>
:deep(.drawer) {
  .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;

    .v-list-group__items > .v-list-item {
      padding-left: 48px;
    }

    .v-list-item__icon:not(:last-of-type) {
      margin-right: 16px;
    }
  }

  .v-navigation-drawer__border {
    display: none;
  }
}

:deep(.v-overlay) {
  isolation: isolate;
  mix-blend-mode: exclusion;
}

.main {
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #f5f5f5;
  overflow: auto;
}
</style>

<style lang="scss">
html.dark {
  background-color: #111 !important;

  body {
    filter: contrast(95%) invert(95%) hue-rotate(180deg) !important;
  }

  input,
  textarea,
  select {
    color: purple;
  }

  img,
  video,
  iframe,
  // canvas, disabled, as we want the PDFs to be dark
  svg,
  embed[type="application/x-shockwave-flash"],
  object[type="application/x-shockwave-flash"],
  *[style*="url("] {
    filter: invert(100%) hue-rotate(-180deg) !important;
  }

  // vuetify adaptions
  .v-progress-circular--visible {
    color: #eee;
  }
}
</style>

<template>
  <v-app class="application">
    <Notify></Notify>
    <SessionExpiredDialog></SessionExpiredDialog>

    <v-navigation-drawer
      v-if="SessionService.isAuthenticated()"
      persistent
      v-model="GlobalMenuService.isMenuOpen"
      app
      class="drawer"
      :width="300"
      :height="'100%'"
      :mobile-breakpoint="mobileBreakpoint"
      style="z-index: 100"
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <h2
                style="font-family: 'Leckerli One'; padding-bottom: 4px"
                :style="{ color: $vuetify.theme.themes.light.primary }"
              >
                Lynx
              </h2>
            </v-list-item-title>

            <v-tooltip bottom :open-delay="500">
              <template v-slot:activator="{ on }">
                <small v-on="on" class="pt-1" style="opacity: 0.5">Version: {{ buildDate }}</small>
              </template>
              <span>Commit {{ commitHash }} | Build at {{ buildDate }} {{ buildTime }}</span>
            </v-tooltip>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="isUpdateAvailable" :link="true" style="background: aquamarine">
          <v-list-item-icon>
            <v-icon v-if="!isLoadingUpdate">system_update_alt</v-icon>
            <v-progress-circular v-if="isLoadingUpdate" indeterminate color="primary"></v-progress-circular>
          </v-list-item-icon>

          <v-list-item-content @click="refreshApp">
            <v-list-item-title>New version is available</v-list-item-title>
            <small style="opacity: 0.5">Click here to reload</small>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list nav class="c-flex column grow" expand style="overflow: auto">
        <v-list-item-group>
          <v-list-item
            v-for="group in groupsWithOneItem"
            :key="group.title"
            style="flex: initial"
            :link="true"
            color="primary"
            v-model="navigationItemsSelectionState[group.items[0].href]"
            :disabled="!isAllowedAccess(group.items[0])"
            @click="selectNavigationItem(group.items[0])"
          >
            <v-list-item-icon>
              <v-icon>{{ group.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ group.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-for="group in groupsWithMultipleItems"
            :key="group.title"
            :value="group.isExpanded"
            :prepend-icon="group.icon"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-title>{{ group.title }}</v-list-item-title>
            </template>

            <v-list-item
              v-for="item in group.items"
              :key="item.title"
              @click="selectNavigationItem(item)"
              :disabled="!isAllowedAccess(item)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list-item-group>
      </v-list>

      <v-list>
        <v-list-item
          v-if="user && user.roles"
          @click="selectNavigationItem({ href: '/users/me', title: 'Account Settings' })"
        >
          <v-list-item-icon><v-icon>account_circle</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-list-item-title v-on="on">
                  <h4>{{ user.firstName }} {{ user.lastName }}</h4>
                  <small>{{ user.email }}</small>
                </v-list-item-title>
              </template>

              <span>
                Following roles are assigned to you:
                <br />
                {{ user.roles.join(", ") }}
              </span>
            </v-tooltip>
          </v-list-item-content>
        </v-list-item>

        <v-list-item style="padding: 0">
          <v-btn
            depressed
            text
            @click="logout"
            style="flex-grow: 1; justify-content: flex-start; letter-spacing: inherit; height: 48px"
          >
            <v-icon left style="margin-right: 18px; margin-left: 0px" size="20">power_settings_new</v-icon>
            <v-list-item-title style="text-transform: initial; text-align: initial">Logout</v-list-item-title>
          </v-btn>

          <v-btn
            style="height: 48px"
            depressed
            text
            @click="
              toggleDarkMode();
              $event.preventDefault();
              $event.stopPropagation();
            "
            ><v-icon>invert_colors</v-icon></v-btn
          >
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main style="height: 100%">
      <router-view :key="$route.fullPath" class="main"></router-view>
    </v-main>
  </v-app>
</template>

<script>
import SessionService from "./common/Session.service";
import UserService from "./common/User.service";
import IsMobile from "./common/IsMobile.mixin";
import ServiceWorkerUpdate from "./common/ServiceWorkerUpdate.mixin";
import GlobalMenuService from "./common/GlobalMenu.service";
import { NavigationItems } from "./router/index";
import SessionExpiredDialog from "./components/SessionExpiredDialog.vue";

const light = "#f5f5f5";
const dark = "#1a1b1a";
const isDarkThemeEnabled = localStorage.getItem("isDarkThemeEnabled") === "true";
if (isDarkThemeEnabled) {
  document.documentElement.classList.add("dark");
  document.querySelector('meta[name="theme-color"]').setAttribute("content", dark);
}

export default {
  mixins: [IsMobile, ServiceWorkerUpdate],

  components: {
    SessionExpiredDialog,
  },

  data() {
    return {
      commitHash: process.env.VUE_APP_GIT_HASH,
      buildDate: process.env.VUE_APP_BUILD_DATE,
      buildTime: process.env.VUE_APP_BUILD_TIME,
      mobileBreakpoint: 1200,
      GlobalMenuService,
      SessionService,
      navigationElements: NavigationItems,
    };
  },

  methods: {
    selectNavigationItem(item) {
      this.$router.push(item.namedWithParams || item.href).catch(err => {});
    },

    logout() {
      SessionService.logout();
    },

    isAllowedAccess(navigationItem) {
      const path = this.$router.options.routes.find(({ path }) => path.includes(navigationItem.href));

      if (!path || !path.meta || !path.meta.roles) {
        return false;
      }

      return SessionService.isAuthenticated() && UserService.hasAnyRole(path.meta.roles);
    },

    toggleDarkMode() {
      if (document.documentElement.classList.contains("dark")) {
        document.documentElement.classList.remove("dark");
        localStorage.setItem("isDarkThemeEnabled", "false");
        document.querySelector('meta[name="theme-color"]').setAttribute("content", light);
      } else {
        document.documentElement.classList.add("dark");
        localStorage.setItem("isDarkThemeEnabled", "true");
        document.querySelector('meta[name="theme-color"]').setAttribute("content", dark);
      }
    },
  },

  computed: {
    navigationItemsSelectionState() {
      return this.navigationElements.reduce((acc, group) => {
        const { href } = group.items[0];
        acc[href] = this.$route.path.includes(href);
        return acc;
      }, {});
    },
    user() {
      return UserService.getUser();
    },
    groupsWithOneItem() {
      return this.navigationElements.filter(group => group.items.length === 1);
    },
    groupsWithMultipleItems() {
      return this.navigationElements.filter(group => group.items.length > 1);
    },
  },
};
</script>
