<template>
  <div class="c-flex column w100 h100 align-center justify-center">
    <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>

    <div
      v-if="!isLoading"
      class="c-flex column justify-center align-center"
      :style="{ 'max-width': '100%', 'min-width': isRelogin ? '100%' : '50%' }"
    >
      <img v-if="!isRelogin" src="../assets/Login_re.svg" style="max-width: 400px" />

      <h3 style="padding: 36px" :style="{ 'padding-bottom': isRelogin ? '8px' : '36px' }">
        {{ isRelogin ? "Session expired" : "Login" }}
      </h3>

      <p v-if="isRelogin" class="pb-8">Please login again</p>

      <v-form style="width: 100%">
        <v-text-field
          prepend-icon="person"
          name="login"
          v-model="username"
          label="Login"
          filled
          type="text"
          v-on:keyup.enter="login"
        >
        </v-text-field>

        <v-text-field
          prepend-icon="lock"
          name="password"
          v-model="password"
          label="Password"
          id="password"
          filled
          v-on:keyup.enter="login"
          :type="isPasswordVisible ? 'text' : 'password'"
          :append-icon="isPasswordVisible ? 'visibility' : 'visibility_off'"
          @click:append="isPasswordVisible = !isPasswordVisible"
        >
        </v-text-field>

        <v-alert color="error" icon="new_releases" :value="error"> Login failed: {{ error }} </v-alert>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$router.push({ name: 'PasswordReset', query: { email: username } })">Reset Password</v-btn>
        <v-btn color="primary" depressed @click="login(username, password)">Login</v-btn>
      </v-card-actions>
    </div>
  </div>
</template>

<script>
import SessionService from "../common/Session.service";
import UserService from "../common/User.service";

export default {
  props: {
    preventRedirect: Boolean,
    isRelogin: Boolean,
  },

  data() {
    return {
      username: UserService.getUser() ? UserService.getUser().email : "",
      password: "",
      error: null,
      isPasswordVisible: false,
      isLoading: false,
    };
  },

  methods: {
    login(username, password) {
      this.isLoading = true;

      SessionService.login(username, password)
        .then(() => {
          if (!this.preventRedirect) {
            this.$router.push(this.$route.query.redirect || "/");
          }

          this.$emit("onLogin");
        })
        .catch(error => (this.error = error.response.data.message))
        .finally(() => (this.isLoading = false));
    },
  },

  name: "Login",
};
</script>
