<template>
  <div>
    <Header><h2>User Administration</h2></Header>

    <p>
      Manage your users by inviting, deleting or editing them. Inviting a new user will send an Email with an invite
      link to activate the account and set a password.
    </p>

    <div class="c-flex row justify-end">
      <edit-user-dialog :userToEdit="userToEdit" @done="fetchUsers(pagination)"></edit-user-dialog>
    </div>

    <v-data-table
      :headers="headers"
      :items="users"
      :loading="isLoading"
      :server-items-length="pagination.totalItems"
      :options="pagination"
      :items-per-page="pagination.itemsPerPage"
      @update:options="fetchUsers"
      :footer-props="pagination.footer"
    >
      <template v-slot:item="{ item: user }">
        <tr>
          <td>
            {{ user.firstName }} {{ user.lastName }}<br />
            <small>{{ user.email }}</small>
          </td>
          <td>
            {{ user.roles.join(", ") }}
          </td>
          <td>
            <v-checkbox disabled v-model="user.isActivated"></v-checkbox>
          </td>
          <td>{{ user.createdAt | format("DD.MM.YYYY") }}</td>
          <td>{{ user.updatedAt | format("DD.MM.YYYY") }}</td>
          <td>
            <div class="c-flex row justify-end">
              <v-btn icon @click="userToEdit = user"><v-icon>edit</v-icon></v-btn>

              <DeleteConfirm :action="deleteUser" :item="user">
                <v-btn icon><v-icon color="red">delete</v-icon></v-btn>
                <template #title>Do you really want to delete the user?</template>
                <template #message>This can not be undone.</template>
              </DeleteConfirm>
            </div>
          </td>
        </tr>
      </template>

      <template slot="no-data">
        <v-alert :value="true" color="info" icon="new_releases"> Es sind noch keine Benutzer angelegt. </v-alert>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import HTTP from "../../common/HTTP";
import PaginationService from "../../common/Pagination.service";
import EditUserDialog from "./EditUserDialog.vue";
import DeleteConfirm from "../util/DeleteConfirm.vue";
import Header from "../util/Header.vue";

export default {
  components: {
    EditUserDialog,
    DeleteConfirm,
    Header,
  },
  data: () => ({
    headers: [
      { text: "User", align: "left", value: "name", width: "20%" },
      { text: "Roles", value: "authorities", width: "30%", sortable: false },
      { text: "Activated", value: "activated", width: "10%" },
      { text: "Created", value: "created", width: "15%" },
      { text: "Updated", value: "updated", width: "15%" },
      { text: "Actions", value: "name", width: "10%", sortable: false, align: "right" },
    ],
    users: [],
    isLoading: false,
    userToEdit: null,
    pagination: PaginationService.generate(),
    totalItems: null,
  }),

  methods: {
    fetchUsers(pagination) {
      this.isLoading = true;

      HTTP.get("/users", { params: PaginationService.toServer(pagination) })
        .then(({ data }) => {
          this.users = data.items;
          this.pagination.totalItems = data.totalItems;
        })
        .catch(error => this.$notifier.error("The users could not be fetched.", error))
        .finally(() => (this.isLoading = false));
    },

    deleteUser(user) {
      HTTP.delete(`/users/${user.id}`)
        .then(() => {
          this.$notifier.info("The user was deleted");
          this.fetchUsers(this.pagination);
        })
        .catch(error => this.$notifier.error("The user could not be delete.", error));
    },
  },
};
</script>
