import SessionService from "./Session.service";

export default {
  data() {
    return {
      isRefreshing: false,
      registration: null,
      isUpdateAvailable: false,
    };
  },

  created() {
    document.addEventListener("serviceWorker:update:available", this.updateAvailable, { once: true });

    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.isRefreshing) return;

      this.isRefreshing = true;
    });
  },

  methods: {
    updateAvailable({ detail: registration }) {
      this.registration = registration;

      if (!this.isUpdateAvailable && SessionService.isAuthenticated()) {
        this.$notifier.info("New version available, update from the navigation sidebar");
      }

      this.isUpdateAvailable = true;
    },

    refreshApp() {
      this.isLoadingUpdate = true;
      this.isUpdateAvailable = false;

      setTimeout(() => {
        window.location.reload();
        this.isLoadingUpdate = false;
      }, 500);
    },
  },
};
