<style lang="scss" scoped>
ul {
  padding: 0 0 16px 16px;
}
</style>

<template>
  <div class="c-flex column w100 h100 align-center justify-center">
    <v-text-field
      style="flex: initial"
      name="password"
      v-model="password"
      label="New Password"
      filled
      :type="isNewPasswordVisible ? 'text' : 'password'"
      :append-icon="isNewPasswordVisible ? 'visibility' : 'visibility_off'"
      @click:append="isNewPasswordVisible = !isNewPasswordVisible"
    >
    </v-text-field>

    <v-btn outlined :loading="isLoading" @click="verify">Verify</v-btn>

    <div v-if="isSuccessFull">
      <p>Die Verifikation Ihres Accounts wurde erfolgreich durchgeführt. Sie können sich nun einloggen.</p>
      <v-btn outlined @click="$router.push('/login')">Zum Login</v-btn>
    </div>

    <div v-if="warning && !isSuccessFull">
      <p>Verifizierung konnte nicht durchgeführt werden. Dies kann folgende Gründe haben:</p>
      <ul>
        <li>Account wurde bereits aktiviert</li>
        <li>Sie sind einem ungültigen Link gefolgt</li>
        <li>
          Falls Sie den Link kopiert haben, prüfen Sie bitte, ob Sie jedes Zeichen und kein Zeichen zuviel kopiert
          haben.
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import HTTP from "../common/HTTP";

export default {
  data() {
    return {
      isLoading: false,
      isSuccessFull: false,
      warning: false,
      isNewPasswordVisible: false,
      password: "",
    };
  },
  methods: {
    verify() {
      this.warning = false;
      this.isLoading = true;

      const verifyObj = {
        userId: this.$route.query.userId,
        token: this.$route.query.token,
        password: this.password,
      };

      HTTP.patch("/auth/verify", verifyObj)
        .then(() => (this.isSuccessFull = true))
        .catch(error => {
          this.isSuccessFull = false;
          this.warning = true;
        })
        .finally(() => (this.isLoading = false));
    },
  },
  name: "VerifyUser",
};
</script>
