<template>
  <div class="c-flex column w100 h100 align-center justify-center">
    <v-text-field style="flex: initial" name="email" v-model="email" label="Your Email" filled type="text">
    </v-text-field>

    <v-btn outlined :loading="isLoading" @click="verify">Send reset password link</v-btn>

    <div v-if="isSuccessFull">
      <p>A password reset link was send to your email account. Use this link to set a new password.</p>
    </div>

    <div v-if="warning && !isSuccessFull">
      <p>A password reset link could not be send. {{ warning }}</p>
    </div>
  </div>
</template>

<script>
import HTTP from "../common/HTTP";
import SessionService from "../common/Session.service";

export default {
  data() {
    return {
      isLoading: false,
      isSuccessFull: false,
      warning: false,
      email: "",
    };
  },

  mounted() {
    SessionService.destroySession();
    this.email = this.$route.query.email;
  },

  methods: {
    verify() {
      this.warning = false;
      this.isLoading = true;

      HTTP.post("/auth/reset-password", { email: this.email })
        .then(() => (this.isSuccessFull = true))
        .catch(error => {
          this.isSuccessFull = false;
          this.warning = error;
        })
        .finally(() => (this.isLoading = false));
    },
  },
  name: "PasswordReset",
};
</script>
