import SessionService from "./Session.service";

export default {
  hasAnyRole(roles) {
    return (
      this.isAdmin() ||
      roles.some(role => SessionService.getSessionUser().roles && SessionService.getSessionUser().roles.includes(role))
    );
  },

  isAdmin() {
    return SessionService.getSessionUser().roles && SessionService.getSessionUser().roles.includes("ADMIN");
  },

  getUser() {
    return SessionService.getSessionUser();
  },
};
