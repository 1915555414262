<template>
  <v-select
    label="Roles"
    :value="value"
    :items="roles"
    @change="updateRole"
    item-text="authority"
    item-value="id"
    multiple
    chips
    deletable-chips
    filled
    hint="Which roles should be assigned to this user?"
    persistent-hint
  >
  </v-select>
</template>

<script>
import HTTP from "@/common/HTTP";

export default {
  props: ["value"],
  data() {
    return {
      roles: [],
    };
  },
  created() {
    this.fetchRoles();
  },
  methods: {
    fetchRoles() {
      HTTP.get("/roles").then(({ data }) => (this.roles = data));
    },
    updateRole(selected) {
      this.$emit("input", selected);
    },
  },
};
</script>
