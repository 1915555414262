<style scoped lang="scss">
.nav {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
  margin-left: 0 !important;
}
</style>

<template>
  <div class="c-flex row align-center">
    <div v-if="isMobile" style="height: 64px" class="c-flex align-center justify-center pr-2">
      <v-btn class="nav" large icon color="primary" @click.stop="toggleDrawer">
        <v-icon>chevron_right</v-icon>
      </v-btn>
    </div>

    <div :style="{ 'padding-top': isMobile || noTopPad ? '' : '16px' }" style="width: 100%">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import IsMobile from "../../common/IsMobile.mixin";
import GlobalMenuService from "../../common/GlobalMenu.service";

export default {
  mixins: [IsMobile],

  props: {
    noTopPad: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    mobileBreakpoint: 1200,
  }),

  methods: {
    toggleDrawer() {
      GlobalMenuService.toggleMenu();
    },
  },
};
</script>
