<style lang="scss" scoped>
.table table {
  table-layout: fixed;

  .cell {
    padding: 8px 0;
    display: inline-grid;

    :deep(.v-btn__content) {
      width: 100%;

      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        text-transform: none;
      }
    }
  }
}
</style>

<template>
  <div>
    <Header><h2>Bookshelf</h2></Header>

    <p>Upload PDF files and read them via the integrated PDF reader.</p>

    <div class="c-flex row justify-end pa-2 align-center">
      <div>
        <v-file-input
          placeholder="Upload PDFs"
          class="pr-2"
          style="min-width: 300px"
          filled
          type="file"
          hide-details
          ref="files"
          multiple
          @change="handleFileUploads"
        ></v-file-input>
      </div>
      <v-btn depressed color="white" @click="submitFiles">Submit</v-btn>
    </div>

    <v-data-table
      class="table"
      :headers="[
        { text: 'Name', align: 'left', width: '55%', sortable: false },
        { text: 'Tags', align: 'left', width: '30%', sortable: false },
        { text: 'Uploaded', width: '10%', sortable: false },
        { text: '', width: '5%', align: 'end', sortable: false },
      ]"
      :items="files"
      :loading="isLoading"
      :hide-default-footer="true"
      :disable-pagination="true"
      :disable-filtering="true"
      :mobile-breakpoint="Number.MAX_VALUE"
      item-key="id"
    >
      <template #item="{ item: file }">
        <tr>
          <td>
            <div class="cell">
              <v-btn text :to="{ name: 'PDFViewer', params: { id: file.id } }">
                <span class="name">{{ file.metadata.filename || file.filename }}</span>
              </v-btn>
            </div>
          </td>

          <td>
            <div class="c-flex grow pa-2">
              <tag-manager
                :existingTags="file.metadata.tags"
                :availableTags="availableTags"
                @change="tags => onTagsChange(file, tags)"
              ></tag-manager>
            </div>
          </td>

          <td>
            <span>
              {{ file.uploadDate | format("DD.MM.YYYY HH:mm") }}
            </span>
          </td>

          <td>
            <div class="c-flex justify-end">
              <v-btn
                icon
                @click="download(file.id, file.metadata.filename || file.filename)"
                :loading="isDownloading[file.id]"
              >
                <v-icon>file_download</v-icon>
              </v-btn>

              <DeleteConfirm :action="deleteFile" :item="file.id">
                <v-btn icon><v-icon color="red">delete</v-icon></v-btn>
                <template #title>Do you really want to delete this file?</template>
              </DeleteConfirm>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import HTTP from "../../common/HTTP";
import DeleteConfirm from "../util/DeleteConfirm.vue";
import TagManager from "../util/TagManager.vue";
import Header from "../util/Header.vue";

export default {
  components: {
    DeleteConfirm,
    TagManager,
    Header,
  },

  data: () => ({
    uploadFiles: null,
    files: [],
    availableTags: [],
    isLoading: false,
    isDownloading: {},
  }),

  mounted() {
    this.fetchFilesAndTags();
  },

  methods: {
    fetchFilesAndTags() {
      this.isLoading = true;

      Promise.all([
        HTTP.get("files")
          .then(response => {
            this.files = response.data;
            this.isDownloading = this.files.reduce((map, file) => {
              map[file.id] = false;
              return map;
            }, {});
          })
          .catch(error => this.$notifier.error("Your files could not be fetched.", error)),
        HTTP.get("tags")
          .then(response => (this.availableTags = response.data))
          .catch(error => this.$notifier.error("Your files could not be fetched.", error)),
      ]).finally(() => (this.isLoading = false));
    },

    updateFileInfoMetaData(file) {
      HTTP.patch(`/files/${file.id}/info`, file.metadata).catch(error =>
        this.$notifier.error("Your pdf file could not be updated.", error),
      );
    },

    submitFiles() {
      if (!this.uploadFiles) {
        return;
      }

      this.isLoading = true;

      const formData = new FormData();

      this.uploadFiles.forEach(file => formData.append("files", file, file.name));

      HTTP.post("/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          this.uploadFiles = null;
          this.$refs.files.reset();
          this.fetchFilesAndTags();
        })
        .catch(error => this.$notifier.error("Your files could not be created.", error));
    },

    handleFileUploads(files) {
      this.uploadFiles = files;
    },

    deleteFile(id) {
      this.isLoading = true;

      HTTP.delete(`files/${id}`)
        .then(() => this.fetchFilesAndTags())
        .catch(error => this.$notifier.error("Your file could not be deleted.", error))
        .finally(() => (this.isLoading = false));
    },

    download(fileId, fileName) {
      this.isDownloading[fileId] = true;

      HTTP.get(`/files/${fileId}`, { responseType: "arraybuffer" })
        .then(pdfFile => {
          const link = document.createElement("a");
          const blob = new window.Blob([pdfFile.data], { type: "application/pdf" });

          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          // some browser needs the anchor to be in the doc
          document.body.append(link);
          link.click();
          link.remove();
          // in case the Blob uses a lot of memory
          setTimeout(() => URL.revokeObjectURL(link.href), 10000);
        })
        .catch(error => this.$notifier.error("Your pdf content could not be fetched.", error))
        .finally(() => (this.isDownloading[fileId] = false));
    },

    onTagsChange(file, tags) {
      file.metadata.tags = tags;
      this.updateFileInfoMetaData(file);
    },
  },
};
</script>
