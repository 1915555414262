<template>
  <div class="c-flex w100 h100">
    <div v-if="isLoading" class="c-flex w100 h100 justify-center align-center">
      <v-progress-circular></v-progress-circular>
    </div>

    <pdf
      v-if="pdfObjectUrl"
      :src="pdfObjectUrl"
      :userDefinedScale="userDefinedScale"
      :userDefinedPage="userDefinedPage"
      :filename="fileInfo.metadata.filename"
      @filenameChanged="setFilename"
      @userPageChange="setPageNumber"
      @userScaleChange="setScale"
      style="height: 100%; width: 100%"
    >
    </pdf>
  </div>
</template>

<script>
import { debounce } from "lodash";

import HTTP from "../../common/HTTP";
import pdf from "./PDFDetail.vue";
import PDFService from "./PDF.service";

export default {
  components: {
    pdf,
  },

  data() {
    return {
      pdfObjectUrl: null,
      fileInfo: null,
      isLoading: false,
      userDefinedPage: 1,
      userDefinedScale: 100,
    };
  },

  mounted() {
    this.fetchPDF();
  },

  methods: {
    fetchPDF() {
      this.isLoading = true;

      PDFService.fetchPDF(this.$route.params.id)
        .then(({ fileInfo, pdfFile }) => {
          this.fileInfo = fileInfo.data;

          this.fileInfo.metadata.filename = this.fileInfo.metadata.filename || this.fileInfo.filename;

          const { currentPage, scale } = this.fileInfo.metadata;
          this.userDefinedPage = currentPage || this.userDefinedPage;

          this.userDefinedScale = (scale || this.userDefinedScale) * 100;

          const blob = new window.Blob([pdfFile.data], { type: "application/pdf" });
          this.pdfObjectUrl = window.URL.createObjectURL(blob);
        })
        .catch(error => this.$notifier.error("Your pdf file could not be fetched.", error))
        .finally(() => (this.isLoading = false));
    },

    updateFileInfoMetaDataDebounced: debounce(function debounced(metadata) {
      this.updateFileInfoMetaData(metadata);
    }, 1000),
    updateFileInfoMetaData(metadata) {
      if (window.navigator.onLine) {
        HTTP.patch(`/files/${this.$route.params.id}/info`, metadata).catch(error =>
          this.$notifier.error("Your pdf file could not be updated.", error),
        );
      }
    },

    setFilename(filename) {
      if (this.fileInfo) {
        this.fileInfo.metadata.filename = filename;
        this.updateFileInfoMetaDataDebounced(this.fileInfo.metadata);
      }
    },

    setPageNumber(pageNumber) {
      if (this.fileInfo) {
        this.fileInfo.metadata.currentPage = pageNumber;
        this.updateFileInfoMetaDataDebounced(this.fileInfo.metadata);
      }
    },

    setScale(scale) {
      if (this.fileInfo) {
        this.fileInfo.metadata.scale = scale;
        this.updateFileInfoMetaDataDebounced(this.fileInfo.metadata);
      }
    },
  },
};
</script>
