import { router } from "../router";
import HTTP from "./HTTP";

const SessionService = {
  refreshTimeout: null,
  sessionExpiredDialogCallback: null,
  URLsToSkipByTheInterceptor: ["/auth/login", "/auth/reset-password", "/auth/verify", "/auth/refresh", "/users/me"],

  handleSessionExpiration() {
    return this.sessionExpiredDialogCallback();
  },

  registerSessionExpiredDialogCallback(sessionExpiredDialogCallback) {
    this.sessionExpiredDialogCallback = sessionExpiredDialogCallback;
  },

  login(username, password) {
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);

    return HTTP.post("/auth/login", params).then(({ data }) => this.handleNewSession(data));
  },

  isSessionExpired() {
    const sessionJSON = localStorage.getItem("session");

    if (!sessionJSON) {
      return true;
    }

    const { expiresAt } = JSON.parse(sessionJSON);

    return !expiresAt || new Date(expiresAt) < new Date();
  },

  handleNewSession({ accessToken, expiresIn }) {
    const safetyMargin = 60;
    const expiresAt = new Date();
    expiresAt.setSeconds(Math.floor(expiresAt.getSeconds() + Number(expiresIn) - safetyMargin));

    this.applyDefaultAuthorizationHeader(accessToken);
    this.setUpJTWRefresh(expiresAt);

    return this.fetchSessionUser().then(user => this.persistSession({ accessToken, expiresAt, user }));
  },

  getSessionUser() {
    const sessionJSON = localStorage.getItem("session");

    if (!sessionJSON) {
      return {};
    }

    return JSON.parse(sessionJSON).user;
  },

  isAuthenticated() {
    return !this.isSessionExpired();
  },

  loadLocalSession() {
    const sessionJSON = localStorage.getItem("session");

    if (!sessionJSON) {
      return;
    }

    const { expiresAt, accessToken } = JSON.parse(sessionJSON);

    if (new Date(expiresAt) < new Date()) {
      this.destroySession();
      return;
    }

    this.applyDefaultAuthorizationHeader(accessToken);
    this.setUpJTWRefresh(new Date(expiresAt));
  },

  refreshSessionUser() {
    return this.fetchSessionUser()
      .then(user => {
        const { expiresAt, accessToken } = JSON.parse(localStorage.getItem("session"));
        this.persistSession({ accessToken, expiresAt, user });
      })
      .catch(error => new Error(`stored session expired: ${error}`));
  },

  refresh() {
    return HTTP.post("/auth/refresh")
      .then(({ data }) => {
        if (this.refreshTimeout) {
          clearTimeout(this.refreshTimeout);
        }

        return this.handleNewSession(data);
      })
      .catch(() => {
        console.error("uupsi, refresh died");
        this.logout({ redirect: router.app.$route.fullPath });
      });
  },

  persistSession(session) {
    localStorage.setItem("session", JSON.stringify({ ...session }));
  },

  destroySession() {
    localStorage.removeItem("session");
  },

  fetchSessionUser() {
    return HTTP.get("/users/me").then(({ data: user }) => user);
  },

  applyDefaultAuthorizationHeader(token) {
    HTTP.defaults.headers.common.Authorization = `Bearer ${token}`;
  },

  /**
   * Refresh the token 60 seconds before the access token expires. This means the user will stay
   * logged in without any noticeable delays.
   *
   * @param expiresAt
   */
  setUpJTWRefresh(expiresAt) {
    const expiresIn = expiresAt - new Date() - 60 * 1000;
    this.refreshTimeout = setTimeout(this.refresh.bind(this), expiresIn);
  },

  logout(query) {
    delete HTTP.defaults.headers.common.Authorization;
    this.destroySession();
    router.push({ path: "/login", query });
  },

  computeExpiresAtDate(expiresIn) {
    const expiresAt = new Date();
    expiresAt.setSeconds(expiresAt.getSeconds() + expiresIn);
    return expiresAt;
  },
};

export default SessionService;
