import NotifyComponent from "./Notify.vue";
import bus from "../Bus";

const Notify = {
  install(Vue) {
    if (this.installed) {
      return;
    }

    Vue.component("Notify", NotifyComponent);

    Vue.prototype.$notifier = {
      info: (text, optionals) => bus.$emit("log", "info", text, optionals),
      warn: (text, optionals) => bus.$emit("log", "warn", text, optionals),
      error: (text, errors, optionals) => bus.$emit("log", "error", text, { ...optionals, errors }),
    };

    this.installed = true;
  },
};

export default Notify;
