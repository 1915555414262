<template>
  <v-dialog v-model="showDialog" width="500" overlay-opacity="1">
    <v-card class="pa-4 c-flex justify-center align-center" style="min-height: 300px">
      <Login @onLogin="resolve" :isRelogin="true" :preventRedirect="true"></Login>
    </v-card>
  </v-dialog>
</template>

<script>
import SessionService from "../common/Session.service";
import Login from "./Login.vue";

export default {
  components: {
    Login,
  },

  data() {
    return {
      showDialog: false,
      resolvePromise: null,
    };
  },

  mounted() {
    SessionService.registerSessionExpiredDialogCallback(this.showSessionExpiredDialog.bind(this));
  },

  methods: {
    showSessionExpiredDialog() {
      this.showDialog = true;
      return new Promise(resolve => (this.resolvePromise = resolve));
    },

    resolve() {
      this.showDialog = false;
      this.resolvePromise();
    },
  },

  name: "SessionExpiredDialog",
};
</script>
