<template>
  <div class="c-flex">
    <Header><h2>Account Settings</h2></Header>

    <div class="c-flex row">
      <div class="c-flex column w50" style="padding-right: 16px">
        <v-text-field v-model="editUser.firstName" hide-details label="First Name" type="text" required filled>
        </v-text-field>

        <v-text-field v-model="editUser.lastName" hide-details label="Last Name" type="text" required filled>
        </v-text-field>

        <v-text-field v-model="editUser.email" hide-details label="Email" type="email" required filled> </v-text-field>

        <div class="c-flex justify-end">
          <v-btn depressed :loading="isLoading" @click="saveDetails">Save</v-btn>
        </div>
      </div>

      <PasswordChange class="c-flex w50"></PasswordChange>
    </div>
  </div>
</template>

<script>
import HTTP from "../../common/HTTP";
import PasswordChange from "./PasswordChange.vue";
import UserService from "../../common/User.service";
import SessionService from "../../common/Session.service";
import Header from "../util/Header.vue";

export default {
  components: {
    PasswordChange,
    Header,
  },

  data: () => ({
    editUser: {
      firstName: "",
      lastName: "",
      email: "",
    },
    isLoading: false,
  }),

  watch: {
    user(user) {
      this.editUser = { ...user };
    },
  },

  methods: {
    saveDetails() {
      this.isLoading = true;

      HTTP.patch(`/users/${UserService.getUser().id}`, { ...this.editUser })
        .then(() => SessionService.refreshSessionUser())
        .then(() => this.$notifier.info("Your changes have been successfully saved."))
        .catch(error => this.$notifier.error("Your changes could not be saved.", error))
        .finally(() => (this.isLoading = false));
    },
  },

  name: "AccountSettings",
};
</script>
