<style scoped lang="scss">
.action-button {
  padding-right: 32px;
}
.loading {
  padding-right: 0;
}
</style>

<template>
  <div class="c-flex column">
    <v-text-field
      name="oldPassword"
      v-model="oldPassword"
      label="Old Password"
      filled
      :type="isOldPasswordVisible ? 'text' : 'password'"
      :append-icon="isOldPasswordVisible ? 'visibility' : 'visibility_off'"
      @click:append="isOldPasswordVisible = !isOldPasswordVisible"
    >
    </v-text-field>
    <v-text-field
      name="newPassword"
      v-model="newPassword"
      label="New Password"
      filled
      :type="isNewPasswordVisible ? 'text' : 'password'"
      :append-icon="isNewPasswordVisible ? 'visibility' : 'visibility_off'"
      @click:append="isNewPasswordVisible = !isNewPasswordVisible"
    >
    </v-text-field>
    <v-text-field
      name="newPasswordConfirm"
      v-model="newPasswordConfirm"
      label="Config new Password"
      filled
      :type="isNewConfirmPasswordVisible ? 'text' : 'password'"
      :append-icon="isNewConfirmPasswordVisible ? 'visibility' : 'visibility_off'"
      @click:append="isNewConfirmPasswordVisible = !isNewConfirmPasswordVisible"
      :rules="[() => this.newPasswordsEqual || 'Passwörter stimmen nicht überein']"
    >
    </v-text-field>

    <div class="c-flex justify-end">
      <v-btn depressed :loading="isLoading" @click="savePassword">Change Password</v-btn>
    </div>
  </div>
</template>

<script>
import HTTP from "../../common/HTTP";
import SessionService from "../../common/Session.service";
import UserService from "../../common/User.service";

export default {
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
      isLoading: false,
      isOldPasswordVisible: false,
      isNewPasswordVisible: false,
      isNewConfirmPasswordVisible: false,
    };
  },

  computed: {
    newPasswordsEqual() {
      return this.newPassword === this.newPasswordConfirm;
    },
  },

  methods: {
    savePassword() {
      this.isLoading = true;

      HTTP.patch(`/users/${UserService.getUser().id}/change-password`, {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      })
        .then(() => SessionService.refreshSessionUser())
        .then(() => this.$notifier.info("Your password has been successfully updated."))
        .catch(error => this.$notifier.error("Your changes could not be saved.", error))
        .finally(() => (this.isLoading = false));
    },
  },

  name: "PasswordChange",
};
</script>
