<template>
  <v-autocomplete
    v-model="tags"
    :items="availableTags"
    color="white"
    hide-details
    hide-selected
    item-text="label"
    @change="updateTags"
    filled
    rounded
    dense
    placeholder="Organize with custom a tag"
    return-object
    :multiple="true"
    style="width: 100%"
  >
    <template #append-item>
      <div class="c-flex column pa-4">
        <v-divider></v-divider>
        <span class="pt-4">Create a new tag:</span>

        <v-text-field
          class="pt-2"
          dense
          hide-details
          placeholder="Label"
          v-model="tagProto.label"
          filled
        ></v-text-field>

        <v-color-picker
          class="pt-2"
          v-model="tagProto.color"
          dot-size="20"
          mode="hexa"
          hide-canvas
          hide-mode-switch
        ></v-color-picker>

        <div class="c-flex row justify-end pt-2">
          <v-btn outlined @click="createTag(tagProto)">
            <v-icon left>add</v-icon>
            Add Tag
          </v-btn>
        </div>
      </div>
    </template>

    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="removeTag(data.item)"
        :color="data.item.color"
        :text-color="generateTextColor(data.item.color)"
      >
        {{ data.item.label }}
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
import HTTP from "../../common/HTTP";

export default {
  props: {
    existingTags: Array,
    availableTags: Array,
  },

  data() {
    return {
      search: "",
      tagProto: { label: "", color: "" },
      tags: [],
      isLoading: false,
    };
  },

  watch: {
    existingTags: {
      handler(tags) {
        this.tags = tags;
      },
      immediate: true,
    },
  },

  methods: {
    updateTags() {
      this.$emit("change", this.tags);
    },

    async createTag(tag) {
      this.isLoading = true;

      const { data: createdTag } = await HTTP.post("/tags", tag);

      this.tags.push(createdTag);
      this.availableTags.push(createdTag);
      this.tagProto = { label: "", color: "" };
      this.updateTags();

      this.isLoading = false;
    },

    removeTag(tag) {
      const index = this.tags.findIndex(({ id }) => tag.id === id);
      this.tags.splice(index, 1);
      this.updateTags();
    },

    generateTextColor(hexColor) {
      const getColorCharacters = str => str.substr(str.length - 8, str.length - 3);
      const getAlpha = str => str.substr(str.length - 2);
      const splitIntoPairs = str => str.split(/(?=(?:..)*$)/);
      const hexToDec = str => parseInt(str, 16);
      const sum = arr => arr.reduce((acc, value) => acc + value, 0);

      const alphaFactor = hexToDec(getAlpha(hexColor)) / 255;
      return sum(splitIntoPairs(getColorCharacters(hexColor)).map(hexToDec)) * alphaFactor > 270 ? "white" : "black";
    },
  },
};
</script>
