import Vue from "vue";
import VueRouter from "vue-router";

import SessionService from "../common/Session.service";
import Login from "../components/Login.vue";

import Users from "../components/users/Users.vue";
import VerifyUser from "../components/VerifyUser.vue";
import PasswordReset from "../components/PasswordReset.vue";
import AccountSettings from "../components/account/AccountSettings.vue";
import PasswordChange from "../components/account/PasswordChange.vue";
import Configuration from "../components/Configuration.vue";
import FileList from "../components/files/FileList.vue";
import PDFViewer from "../components/files/PDFViewer.vue";

Vue.use(VueRouter);

export const NavigationItems = [
  {
    title: "Bookshelf",
    icon: "chrome_reader_mode",
    items: [{ title: "Bookshelf", href: "/files" }],
    isExpanded: true,
    showOnWelcome: true,
  },
  {
    title: "System",
    icon: "settings",
    items: [
      { title: "User Administration", href: "/users" },
      { title: "Health", href: "/else" },
    ],
    isExpanded: false,
  },
];

const routes = [
  {
    path: "/",
    name: "Welcome",
    redirect: "/files",
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    path: "/users",
    name: "users",
    component: Users,
    meta: { requiresAuth: true, roles: ["ADMIN"] },
  },
  {
    path: "/verify",
    name: "verify",
    component: VerifyUser,
    props: true,
    meta: { requiresAuth: false },
  },
  {
    path: "/reset-password",
    name: "PasswordReset",
    component: PasswordReset,
    props: true,
    meta: { requiresAuth: false },
  },
  {
    path: "/users/me",
    name: "AccountSettings",
    component: AccountSettings,
    meta: { requiresAuth: true },
  },
  {
    path: "/users/me/change-password",
    name: "ChangePassword",
    component: PasswordChange,
    meta: { requiresAuth: true },
  },
  {
    path: "/conf",
    name: "Configuration",
    component: Configuration,
    meta: { requiresAuth: true, roles: ["ADMIN"] },
  },
  {
    path: "/files",
    component: FileList,
    name: "FileList",
    meta: { requiresAuth: true, roles: ["ADMIN", "USER"] },
  },
  {
    path: "/files/:id/pdf",
    component: PDFViewer,
    name: "PDFViewer",
    meta: { requiresAuth: true, roles: ["ADMIN", "USER"] },
  },
];

export const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const onBeforeEach = async (to, from, next) => {
  // First load
  if (from.name === null) {
    // Try retrieving a fresh access token on first load from the local session
    try {
      await SessionService.loadLocalSession();
    } catch (error) {
      console.error("Error on router first load", error);
    }
  }

  if (to.meta && to.meta.requiresAuth) {
    if (SessionService.isAuthenticated()) {
      return next();
    }

    console.log("not authenticated", localStorage.getItem("session"), to, from);
    return next("/login");
  }

  return next();
};

router.beforeEach(onBeforeEach);
