import axios from "axios";
import SessionService from "./Session.service";

const HTTP = axios.create({ baseURL: process.env.VUE_APP_API });

HTTP.interceptors.request.use(
  async config => {
    if (SessionService.isSessionExpired() && !SessionService.URLsToSkipByTheInterceptor.includes(config.url)) {
      await SessionService.handleSessionExpiration();
    }

    return config;
  },
  error => Promise.reject(error),
);

export default HTTP;
