import Vue from "vue";
import dayjs from "dayjs";
import "@fontsource/jetbrains-mono";
import "@fontsource/lato";
import "@fontsource/leckerli-one";

import App from "./App.vue";
import "./registerServiceWorker";
import { router } from "./router";
import vuetify from "./plugins/vuetify";
import "./styles/base.scss";
import "./styles/vuetifyOverrides.scss";
import "./styles/defaultOverrides.scss";
import "./styles/defaultElements.scss";

Vue.config.productionTip = false;

Vue.filter("format", (value, format) => {
  if (!value) return "";

  return dayjs(value).format(format);
});

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount("#app");
